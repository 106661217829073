import React from 'react';
import { Link } from 'gatsby';
import SEO from '../components/SEO';
import { ImageGrid, ImageCaption } from '../styles/ImageGrid';
import { TripHeader } from '../components/TripHeader';
import Usatlincolnhh from '../assets/images/usatlincolnhh.jpg';
import Buddyleeabbybrett from '../assets/images/buddyleeabbybrett.jpg';
import Lincolnhh from '../assets/images/lincolnhh.jpg';
import Signednapkins from '../assets/images/signednapkins.jpg';

export default function Page() {
  return (
    <>
      <SEO title="Lincoln: 1-19-01" />
      <div>
        <TripHeader>
          <h2>Lincoln</h2>
          <p>2-18-01</p>
          <img
            src={Usatlincolnhh}
            alt="Abby, Patrick, Brett, Buddy Lee, Bailey, and Pooh with Sheila at the Lincoln Huddle House"
          />
          <ImageCaption>
            Abby, Patrick, Brett, Buddy Lee, Bailey, and Pooh with Sheila at the
            Lincoln Huddle House
          </ImageCaption>
        </TripHeader>
        <ImageGrid>
          <div>
            <img src={Buddyleeabbybrett} alt="Buddy Lee, Abby, and Brett" />
            <ImageCaption>Buddy Lee, Abby, and Brett</ImageCaption>
          </div>
          <div>
            <img src={Lincolnhh} alt="The Lincoln Huddle House" />
            <ImageCaption>The Lincoln Huddle House</ImageCaption>
          </div>
          <div>
            <img src={Signednapkins} alt="Signed Napkins" />
            <ImageCaption>Signed Napkins</ImageCaption>
          </div>
        </ImageGrid>
        <p>
          Three days in a row! We have been to three Huddle Houses in three
          days. Pooh, Brett, Patrick, Bailey, Abby, and Buddy Lee just got back
          from the Huddle House in Lincoln. We got Buddy Lee tonight and made
          this wonderful chum a member! Check out the Huddle House Fan Club's
          newest friend in the "<Link to="/members">Members</Link>" section. It
          was great! We were rather cold after seeing the XFL game in
          Birmingham, so we dropped in the Lincoln Huddle House for some good
          old hot chocolate. We were served by Sheila, a very delightful
          waitress, who was very nice. She signed a napkin for us and loved
          Buddy Lee. All in all a great time!
        </p>
      </div>
    </>
  );
}
